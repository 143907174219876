import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Banner } from '../banner-cta'

const CollarDetail = () => {
  const { hero } = useStaticQuery(graphql`
    {
      hero: file(
        relativePath: { eq: "img/bsr-embroidered-collar-detail.jpg" }
      ) {
        childImageSharp {
          fluid(maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Banner
      image={{
        ...hero.childImageSharp,
        alt: 'BSR Embroidered Suit Collar Detail',
        style: {
          minHeight: '360px',
          maxHeight: '500px',
        },
        imgStyle: {
          // filter: 'brightness(60%)',
          objectFit: 'cover',
          fontFamily: 'object-fit: cover;',
        },
      }}
    ></Banner>
  )
}

export default CollarDetail
