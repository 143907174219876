import React from 'react'
import Seo from '../components/SEO'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import PageHero from '../components/shared/page-hero'
import PageBody, {
  PageContainer,
  ContentImage,
} from '../components/shared/page-body'
import CollarDetailBanner from '../components/banners/collar-detail'

const JacketsBlazersPage = ({ data }) => (
  <Layout>
    <Seo
      title="Tailored Sports Jackets &amp; Blazers"
      pathname="/sports-jackets-blazers/"
    />
    <PageHero
      image={data.hero.childImageSharp}
      title="Tailored Sports Jackets &amp; Blazers"
    />
    <PageBody>
      <PageContainer maxWidth={840} padded>
        <h1 className="heading--h1">Tailored Sports Jackets &amp; Blazers</h1>
        <ContentImage
          {...data.pinkJacket.childImageSharp}
          alt="Pink Tailored Sports Jacket"
          style={{ maxHeight: '720px' }}
          imgStyle={{ objectPosition: '50% 15%' }}
        />
        <ContentImage
          {...data.blueWhiteCheck.childImageSharp}
          alt="Blue and White Check Sports Jacket"
          style={{ maxHeight: '720px' }}
          imgStyle={{ objectPosition: '50% 15%' }}
        />
        <ContentImage
          {...data.redStripeBlazer.childImageSharp}
          alt="Red Strip Blazer"
          style={{ maxHeight: '720px' }}
          imgStyle={{ objectPosition: '50% 15%' }}
        />
        <p>Fabrics that perform better under pressure.</p>
        <p>
          A cloth and style that can transition from the boardroom to a cocktail
          party.
        </p>
        <p>
          A fit that will instil confidence, style, elegance and recognition.
        </p>
        <p>
          Trains, planes or cars, a jet-set lifestyle requires fabrics to
          perform and travel well.
        </p>
        <p>
          Explore 100’s of the finest fabrics available to you. Be exclusive,
          stylish and unique.
        </p>
        <p>
          Fabrics from pure wool, wool/silk, wool/cashmere, mohair, even the
          sleek, soft fine texture of bamboo.
        </p>
        <p>Luxurious and durable.</p>
        <ContentImage
          {...data.redLining.childImageSharp}
          alt="Red Tailored Jacket Lining Fox Tailoring Logo"
          style={{ maxHeight: '640px' }}
        />
      </PageContainer>
    </PageBody>
    <CollarDetailBanner />
  </Layout>
)

export default JacketsBlazersPage

export const query = graphql`
  {
    hero: file(relativePath: { eq: "img/tweed-sports-jacket-racecourse.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    redLining: file(relativePath: { eq: "img/red-jacket-lining.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 720, quality: 65) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pinkJacket: file(relativePath: { eq: "img/pink-sports-jacket.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 720, quality: 65) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    blueWhiteCheck: file(
      relativePath: { eq: "img/blue-white-check-sports-jacket.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 720, quality: 65) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    redStripeBlazer: file(relativePath: { eq: "img/red-stripe-blazer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 720, quality: 65) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
